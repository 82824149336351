#amenities {
    position: relative;
    padding: 45px 0 15px 0;
    text-align: center;
}

#amenities.home-amenities {
    max-height: 100%;
    background-image: url(../../public/img/zaytun-nite.jpeg);
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff;
}

#amenities.home-amenities:after {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

#amenities .container {
    position: relative;
    z-index: 1;
}

#amenities.home-amenities h2,
#amenities.home-amenities h3,
#amenities.home-amenities p {
    color: #ffffff;
}

#amenities h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}

#amenities .icons {
    margin-bottom: 30px;
}

#amenities .icon {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
    transition: 0.5s;
}

#amenities .icon.icon-1 {
    background-image: url(../../public/icon/shield-hover.png);
}

#amenities .icon.icon-1:hover {
    background-image: url(../../public/icon/shield.png);
}

#amenities .icon.icon-2 {
    background-image: url(../../public/icon/air-conditioner-hover.png);
}

#amenities .icon.icon-2:hover {
    background-image: url(../../public/icon/air-conditioner.png);
}

#amenities .icon.icon-3 {
    background-image: url(../../public/icon/bathtub-hover.png);
}

#amenities .icon.icon-3:hover {
    background-image: url(../../public/icon/bathtub.png);
}

#amenities .icon.icon-4 {
    background-image: url(../../public/icon/shower-hover.png);
}

#amenities .icon.icon-4:hover {
    background-image: url(../../public/icon/shower.png);
}

#amenities .icon.icon-5 {
    background-image: url(../../public/icon/towel-hover.png);
}

#amenities .icon.icon-5:hover {
    background-image: url(../../public/icon/towel.png);
}

#amenities .icon.icon-6 {
    background-image: url(../../public/icon/television-hover.png);
}

#amenities .icon.icon-6:hover {
    background-image: url(../../public/icon/television.png);
}

#amenities .icon.icon-7 {
    background-image: url(../../public/icon/wifi-hover.png);
}

#amenities .icon.icon-7:hover {
    background-image: url(../../public/icon/wifi.png);
}

#amenities .icon.icon-8 {
    background-image: url(../../public/icon/call-hover.png);
}

#amenities .icon.icon-8:hover {
    background-image: url(../../public/icon/call.png);
}

#amenities .icon.icon-9 {
    background-image: url(../../public/icon/minibar-hover.png);
}

#amenities .icon.icon-9:hover {
    background-image: url(../../public/icon/minibar.png);
}

#amenities .icon.icon-10 {
    background-image: url(../../public/icon/kitchen-hover.png);
}

#amenities .icon.icon-10:hover {
    background-image: url(../../public/icon/kitchen.png);
}