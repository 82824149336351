#rooms {
    position: relative;
    padding: 45px 0 60px 0;
    background-color: #FFffff;
}

#rooms .room-img,
#rooms .room-des,
#rooms .room-rate {
    position: relative;
    top: 50%;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

#rooms .room-des {
    width: 100%;
    text-align: center;
}

#rooms .room-img {
    overflow: hidden;
}

#rooms .room-img img {
    transition: 0.3s;
}

#rooms .room-img:hover img {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.box12 .icon li a {
    margin-right: 0;
}

#rooms h3 {
    font-size: 16px;
    margin-bottom: 15px;
}

#rooms .room-size {
    margin: 0 0 25px 0;
    padding: 0;
}

#rooms .room-size li {
    display: inline-block;
    font-size: 14px;
    margin-right: 15px;
    list-style-type: none;
}

#rooms .room-size li i {
    margin-right: 6px;
}

#rooms .room-icon {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
}

#rooms .room-icon li {
    list-style-type: none;
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
    transition: 0.5s;
}

@media (max-width: 575.98px) {   
    #rooms .room-icon li {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        margin-bottom: 10px;
    }
    
}


#rooms .room-icon li.icon-1 {
    background-image: url(../../public/icon/shield.png);
}

#rooms .room-icon li.icon-1:hover {
    background-image: url(../../public/icon/shield-hover.png);
}

#rooms .room-icon li.icon-2 {
    background-image: url(../../public/icon/air-conditioner.png);
}

#rooms .room-icon li.icon-2:hover {
    background-image: url(../../public/icon/air-conditioner-hover.png);
}

#rooms .room-icon li.icon-3 {
    background-image: url(../../public/icon/bathtub.png);
}

#rooms .room-icon li.icon-3:hover {
    background-image: url(../../public/icon/bathtub-hover.png);
}

#rooms .room-icon li.icon-4 {
    background-image: url(../../public/icon/shower.png);
}

#rooms .room-icon li.icon-4:hover {
    background-image: url(../../public/icon/shower-hover.png);
}

#rooms .room-icon li.icon-5 {
    background-image: url(../../public/icon/towel.png);
}

#rooms .room-icon li.icon-5:hover {
    background-image: url(../../public/icon/towel-hover.png);
}

#rooms .room-icon li.icon-6 {
    background-image: url(../../public/icon/television.png);
}

#rooms .room-icon li.icon-6:hover {
    background-image: url(../../public/icon/television-hover.png);
}

#rooms .room-icon li.icon-7 {
    background-image: url(../../public/icon/wifi.png);
}

#rooms .room-icon li.icon-7:hover {
    background-image: url(../../public/icon/wifi-hover.png);
}

#rooms .room-icon li.icon-8 {
    background-image: url(../../public/icon/call.png);
}

#rooms .room-icon li.icon-8:hover {
    background-image: url(../../public/icon/call-hover.png);
}

#rooms .room-icon li.icon-9 {
    background-image: url(../../public/icon/minibar.png);
}

#rooms .room-icon li.icon-9:hover {
    background-image: url(../../public/icon/minibar-hover.png);
}

#rooms .room-icon li.icon-10 {
    background-image: url(../../public/icon/kitchen.png);
}

#rooms .room-icon li.icon-10:hover {
    background-image: url(../../public/icon/kitchen-hover.png);
}

#rooms .room-rate {
    text-align: center;
}

#rooms .room-rate h3 {
    color: #FFffff;
    font-size: 18px;
    font-weight: 600;
    padding: 15px 0;
    background: #222222;
    margin: 0;
    text-transform: uppercase;
}

#rooms .room-rate h1 {
    color: #ffffff;
    font-size: 50px;
    font-weight: 900;
    padding: 15px 0;
    background: #353535;
}

#rooms .room-rate a {
    display: block;
    padding: 7px 30px;
    color: #dfded8;
    background: #222222;
}

#rooms .room-rate a:hover {
    color: #222222;
    background: gray;
    text-decoration: none;
}

#rooms hr {
    width: 300px;
    margin: 30px auto;
    text-align: center;
}
.modal-content{
    width: 700px;
}
.modal .modal-header button {
    margin: 0 auto;
    padding: 0px 8px 5px 8px;
    font-size: 25px;
    border-radius: 20px;
    background: #FFD700;
    color: #fff;
}

.modal .modal-body img {
    height: 100%;
    width: 100%;
}

/* my style */
.room-details {
    font-weight: 800;
    color: black;
}

.modal .modal-body h2 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.modal .modal-body p {
    margin-bottom: 15px;
}

/* .modal .modal-body .modal-link {
    position: relative;
    text-align: center;
} */

.modal .modal-body a {
    display: inline-block;
    margin: 0 auto;
    padding: 8px 30px;
    background: #222222;
    color: #FFD700;
    cursor: pointer;
    border: none;
}

.modal .modal-body a:hover {
    color: #222222;
    background: #FFD700;
}

.modal .port-slider-nav .slick-track {
    padding: 30px 0 15px 0;
}

.modal .port-slider-nav .slick-center img {
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}
@media (max-width: 768px) { 
    #rooms .room-rate h1 {
        font-size: 30px;
    }
}
@media (max-width: 820px) { 
    #rooms .room-rate h1 {
        font-size: 30px;
    }
}
@media (max-width: 834px) { 
    #rooms .room-rate h1 {
        font-size: 30px;
    }
}
@media (max-width: 800px) { 
    #rooms .room-rate h1 {
        font-size: 30px;
    }
}
.room-des h3 a:hover{
    color: blue;
}
.link{
    font-weight: 700;
    color: #222222;
    border: none;
    background-color: white;
}
.link:hover{
color: blue;
}