.reveal {
        /* position: relative; */
        transform: translateY(150px);
        opacity: 100;
        transition: 2s all ease;
    }

    .reveal.active {
        transform: translateY(0);
        opacity: 1;
    }