#welcome {
    position: relative;
    padding: 45px 0 60px 0;
    background: #f2f2f2;
    text-align: center;
}

#welcome h3 {
    font-size: 50px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 15px;
}

#welcome p {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 25px;
}

@media (max-width: 767.98px) {
    #welcome h3 {
        font-size: 40px;
        font-weight: 700;
    }
    
    #welcome p {
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
    }
}

@media (max-width: 575.98px) {
    #welcome h3 {
        font-size: 30px;
        font-weight: 600;
        letter-spacing: 0;
    }
    
    #welcome p {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
    }
}

#welcome a {
    display: inline-block;
    padding: 7px 30px;
    color: #FFD700;
    background: #222222;
}

#welcome a:hover {
    color: #222222;
    background: #FFD700;
}
