#search {
    position: relative;
    background: #ffffff;
    padding: 30px 0 20px 0;
}

@media (max-width: 767.98px) {
    #search {
        padding: 30px 0 35px 0;
    }
}

@media (max-width: 575.98px) {
    #search {
        padding: 30px 0 35px 0;
    }
    
    #search .kid {
        margin-top: 15px;
    }
}

#search label {
    font-size: 14px;
    font-weight: 600;
}

#search .input-group-text {
    border-radius: 0;
}

#search input,
#search select,
#search button {
    box-shadow: none;
    border-radius: 0;
}

#search input:focus,
#search select:focus,
#search button:focus {
    border-color: #FFD700;
}

#search .btn {
    margin-top: 31px;
    color: #FFD700;
    background: #222222;
    border-color: #222222;
}

#search .btn:hover {
    color: #222222;
    background: #FFD700;
    border-color: #FFD700;
}

#search .dropdown-menu {
    width: 230px;
}

@media (max-width: 767.98px) {
    #search .btn {
        margin-top: 15px;
    }
    
    #search .kid {
        margin-top: 15px;
    }
}
