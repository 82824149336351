#booking {
    position: relative;
    padding: 45px 0 60px 0;
    background-color: #FFFFFF;
}

#booking .container {
    max-width: 990px;
}

#booking label {
    font-size: 14px;
    font-weight: 600;
}

#booking .form-control {
    width: 300px;
    height: 35px;
    border: 1px solid #ddd;
    border-radius: 0;
    line-height: 24px;
    padding: 5px 20px;
    background-color: transparent;
    color: #222222;
    font-size: 16px;
    font-weight: 200;
}

#booking .form-control:focus {
    border-color: #FFD700;
    box-shadow: none;
    outline: 0 none;
}

#booking .button button {
    display: inline-block;
    margin-top: 20px;
    font-size: 16px;
    color: #FFD700;
    line-height: 24px;
    padding: 10px 30px 8px 30px;
    border-radius: 0;
    background: #222222;
    border: none;
    font-weight: 400;
    cursor: pointer;
    transition: all .3s;
}

#booking .button button:hover {
    color: #222222;
    background: #FFD700;
}

#booking .help-block ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

#booking .dropdown-menu {
    width: 230px;
}
.blo{
    display: block;
}
 .row2 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
   margin: 20px;
    
}


@media (max-width: 767.98px) {
    h2 {
        font-size: 26px;
        font-weight: 600;
    }
}

@media (max-width: 575.98px) {
    h2 {
        font-size: 18px;
        font-weight: 400;
    }
}

/* ends here*/