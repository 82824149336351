#about {
  position: relative;
  padding: 45px 0 60px 0;
  background: #f2f2f2;
}

#about hr {
  width: 50%;
  margin: 45px auto;
}

#about .img-cols,
#about .content-cols {
  padding: 0;
}

#about .content-cols {
  background: #222222;
}

#about .img-col,
#about .content-col {
  position: relative;
  top: 50%;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

#about .content-col {
  background: #222222;
  padding: 30px;
}

#about .img-col {
  overflow: hidden;
}

#about .img-col img {
  transition: 0.3s;
  width: 100%;
}

#about .img-col:hover img {
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

#about .content-col h3 {
  color: #ffd700;
  font-weight: 600;
  margin-bottom: 15px;
}

@media (max-width: 767.98px) {
  #about .content-col {
    text-align: center;
  }

  #about .content-col h3 {
    font-weight: 400;
    margin-top: 15px;
  }
}

#about .content-col p {
  color: #ddd;
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
  margin-bottom: 20px;
}

#about .content-col a {
  display: inline-block;
  background: #ffd700;
  font-weight: 400;
  color: #222222;
  padding: 10px 30px;
}

#about .content-col a:hover {
  background: #ffffff;
  color: #ffd700;
}

#about .content-col a:focus {
  color: #fff;
}

@media (max-width: 800px) {
  #about .img-col img {
    transition: 0.3s;
    width: 450px;
  }
  .img-fluid {
    /* max-width: 100%; */
    height: 450px;
  }
}
@media (max-width: 820px) {
  #about .img-col img {
    transition: 0.3s;
    width: 450px;
  }
  .img-fluid {
    /* max-width: 100%; */
    height: 450px;
  }
}
@media (max-width: 834px) {
  #about .img-col img {
    transition: 0.3s;
    width: 450px;
  }
  .img-fluid {
    /* max-width: 100%; */
    height: 450px;
  }
}