#headerSlider {
    position: relative;
    max-width: 100%;
    max-height: calc(100vh - 60px);
    margin: 0 auto;
    text-align: center;
}

#headerSlider img {
    width: 100%;
}

#headerSlider .carousel-caption {
    position: absolute;
    top: 400px;
    left: 0;
    right: 0;
    /* opacity: 40%; */
    height: 100px;
    /* background: rgba(128, 128, 128, 0.089) */
    z-index: 1;
}

#headerSlider .carousel-caption h1 {
    color: #fffff0;
    font-size: 30px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 5px;
}

@media (max-width: 767.98px) {
    #headerSlider .carousel-caption h1 {
        font-size: 25px;
        font-weight: 700;
        letter-spacing: 3px;
    }
}

@media (max-width: 575.98px) {
    #headerSlider .carousel-caption h1 {
        font-size: 25px;
        font-weight: 500;
        letter-spacing: 1;
    }
}

#headerSlider .animated {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
}