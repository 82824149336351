/* ================================================= */
/* ------------------ General CSS ------------------ */
/* ================================================= */

* {
  box-sizing: border-box;
}

body {
  color: #666;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  background: #ffffff;
}

a {
  color: #222222;
  transition: 0.5s;
}

a:hover,
a:active,
a:focus {
  color: #fff;
  outline: none;
  text-decoration: none;
}

p {
  color: #666;
  font-size: 14px;
  line-height: 24px;
  padding: 0;
  margin: 0 0 15px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  color: #666;
  font-weight: 700;
}

@media (max-width: 767.98px) {
  h2 {
    font-size: 26px;
    font-weight: 600;
  }
}

@media (max-width: 575.98px) {
  h2 {
    font-size: 18px;
    font-weight: 400;
  }
}

.section-header {
  position: relative;
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 45px;
}

.section-header h2 {
  font-size: 45px;
  font-weight: 800;
  letter-spacing: 1px;
  margin-bottom: 15px;
}

.section-header p {
  margin: 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 25px;
}

@media (max-width: 767.98px) {
  .section-header h2 {
    font-size: 35px;
    font-weight: 700;
  }
}

@media (max-width: 575.98px) {
  .section-header h2 {
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 0;
  }
}
#success {
    background-color: #FFD700;
    margin: 20px auto;
    width: 314px;
    height: 71px;
    text-align: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity for the blur effect */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Adjust the z-index as needed */
}

.loader-dev {
  width: 50px;
}
.loader {
  display: flex;
  color: #222222;
  font-size: 10px;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.3s infinite linear;
  transform: translateZ(0);
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
/* Add this to your CSS file or style section */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*video player style */
/* Default styles */
.video-container {
  width: 100%;
  max-width: 640px; /* Adjust maximum width as needed */
  margin: 0 auto 70px;
}

.video-player {
  width: 100%;
}

/* Tablet styles */
@media only screen and (min-width: 768px) {
  .video-container {
    max-width: 768px; /* Adjust maximum width for tablets */
  }
}

/* Desktop styles */
@media only screen and (min-width: 1024px) {
  .video-container {
    max-width: 1024px; /* Adjust maximum width for desktop */
  }
}
