/* Example styles for react-datepicker */

/* Style the input field */
.react-datepicker__input {
  padding: 8px;
  font-size: 16px;
  width: 50px;
}

/* Style the calendar container */
.react-datepicker-popper {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Style the days in the calendar */
.react-datepicker__day {
  color: #333;
}

/* Style the selected day in the calendar */
.react-datepicker__day--selected {
  background-color: #3498db;
  color: #fff;
}

/* Style the calendar header */
.react-datepicker__header {
  background-color: #3498db;
  color: #fff;
}
