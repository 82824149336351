
 
 
 
 
 #header {
    background: #222222;
    position: relative;
    height: 100px;
    padding: 20px;
}

#header .logo {
    margin-left: 10rem;
    left: 0;
    display: inline-block;
    position: absolute;
    line-height: 55px;
}

#header .logo img {
    max-height: 50px;
}

.main-menu {
    padding: 0 130px;
    width: 100%;
    text-align: center;
}

.main-menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.main-menu ul li {
    display: inline-block;
}

.main-menu ul li:hover a,
.main-menu ul li.active a {
    color: #ffffff;
}

.main-menu ul li a {
    color: #FFD700;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 200;
    padding: 0 10px;
    line-height: 60px;
    display: block;
    transition: color .25s ease, background-color .25s ease;
}
.mobile-menu-btn {
    color: #FFD700;
    text-align: center;
    font-size: 30px;
    line-height: 57px;
    display: none;
    white-space: nowrap;
    margin-right: 20px;
}

.mobile-menu-btn i {
    cursor: pointer;
}

.mobile-menu {
    display: none;
    background: #2a2a2a;
    border-top: #343434;
}

.mobile-menu .top-menu {
    display: block;
}

.mobile-menu .top-menu ul {
    padding: 0;
}

.mobile-menu .top-menu ul li {
    display: block;
    text-align: center;
}

.mobile-menu .top-menu ul li a {
    padding: 0;
    line-height: 40px;
}

.mobile-menu .top-menu ul li a:hover {
    text-decoration: none;
}

.phone {
    position: absolute;
    padding-right: 100px;
    padding-left: 10px;
    right: 0;
    color: #FFD700;
    line-height: 60px;
    cursor: pointer;
    transition: color .25s ease;
    font-size: 18px;
    font-weight: 600;
}
.num{
    color: #FFD700;
}
.phone:hover {
    color: #ffffff;
}

.phone i {
    padding-right: 10px;
    font-size: 20px;
}
@media (max-width: 575.98px) {
    #header .logo {
        padding-left: 5px;
    }

    #header .phone {
        display: none;
    }

    #header .mobile-menu-btn {
        padding-right: 5px;
        display: block;
        text-align: right;
        font-size: 20px;
        line-height: 55px;
    }

    .main-menu {
        padding: 0 30px;
    }
}
@media (max-width: 1199.98px) {
    .main-menu {
        display: none;
    }

    .mobile-menu-btn {
        display: block;
    }
} 

