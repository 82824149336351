#call-us {
  position: relative;
  text-align: center;
  padding: 45px 0 60px 0;
  background: #f2f2f2;
}

#call-us .section-header::after {
  background: #f2f2f2;
}

#call-us a {
  display: inline-block;
  padding: 15px 30px;
  background: #ffd700;
  color: #222222;
  font-size: 28px;
  font-weight: 800;
  letter-spacing: 2px;
  text-decoration: none;
}

#call-us a:hover {
  color: #ffffff;
  background: #000000;
  text-decoration: none;
}

@media (max-width: 575.98px) {
  #call-us a {
    padding: 10px 30px;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 1px;
  }
}
