.foot {
  background: #222222;
}
.logo-footer img {
  width: 150px;
  height: 150px;
}
#footer .social {
  position: relative;
  margin-bottom: 20px;
}

#footer a {
  color: #ffd700;
}

#footer a:hover {
  color: #ffffff;
}

#footer .social a {
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 5px 10px;
  background: #ffd700;
  color: #222222;
  font-size: 20px;
}

#footer .social a:hover {
  background: #ffffff;
  color: #ffd700;
}

#footer p {
  margin: 0;
  font-size: 18px;
  color: #ffffff;
}
.btn:hover {
  background-color: #ffd700;
}
.site {
  color: white;
  font-weight: 800;
}
.site:hover {
  color: #ffd700;
}
.fw{
  color: #ffffff;
}
.fw:hover{
  color: #ffd700;
}